$ = jQuery;

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0) ==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}
function normaliseGACookie(str){
	if(str){
		var gaCookie = str.split('.');
		return gaCookie[2] + "." + gaCookie[3];
	}else{
		return '';
	}
}

function getGoogleCid() {
	var gaStr = getCookie('_ga');
	return normaliseGACookie(gaStr);
}

/*Functions*/

/**
 * Scroll to element
 * @param item
 */
function scrollToElement(item) {
	var hT = item.offset().top,
		hH = item.outerHeight(true);
	return hT - 60 <= $(document).scrollTop() && hT + hH - 60 > $(document).scrollTop() ? item.attr('id') : false;
}

/**
 * Highlight toc sidebar
 */
function highlightTocSidebar() {
	var tocHeadingsList = $('.content_casino h2');
	var sidebarTocList = $('.sidebar_table_of_content a');
	tocHeadingsList.each(function () {
		var id = '#' + scrollToElement($(this));
		if (id) {
			sidebarTocList.each(function () {
				if ($(this).attr('href') == id) {
					sidebarTocList.parent().removeClass('active');
					$(this).parent().addClass('active');
				}
			});
		}
	});
}

/*table expand*/
function tableExpand() {
	var expandCount = 5;

	function expandClick(time) {
		$('.casinos_list').each(function () {
			$(this).find('tr').each(function (index, element) {
				if (index > expandCount) {
					$(this).toggleClass('hidden');
				}
			});
		});
	}

	expandClick(1);
	$('.expand_table').on('click', function () {
		$(this).prev().find('tr').each(function (index, element) {
			if (index > expandCount) {
				$(this).toggleClass('hidden');
			}
		});
		$(this).toggleClass('active');
	});
}

function tableResExpand() {
	var expandCount = $('.expand-casinos').data('item-number');

	function expandResClick(time) {
		$('.all_casino_list').each(function () {
			$(this).find('.all_casinos_row').each(function (index, element) {
				if (index > expandCount) {
					$(this).toggleClass('hidden');
				}
			});
		});
	}

	//expandResClick(1);
	$('.js-expand-table').on('click', function () {
		var numbItems = $(this).data('item-number');
		$(this).prev().find('.all_casinos_row').each(function (index, element) {
			if (index > numbItems) {
				$(this).toggleClass('hidden');
			}
		});
		if ($(this).hasClass('active')) {
			$('html,body').animate({scrollTop: $('.all_casinos').offset().top - 150}, 500);
		}
		$(this).toggleClass('active');
	});
}

/*Redirection Button*/
function toReferral() {
	$('body').on('click', '[data-casino-id]', handleFollowReferral);
}

function handleFollowReferral(e){
	//var btn = $('[data-casino-id]');
	var cid = $('body').data('cid');
	if( cid === '' ){
		cid = getGoogleCid();
	}

	var location = $('body').data('current-url');
	e.preventDefault();
	var a = $(this),
		id = a.attr('data-casino-id'),
		promo_id = a.data('promo-id'),
		element = a.data('ga-convert-element'),
		casino = a.data('casino-name'),
		position = a.data('table-position'),
		link = '/to-casino/?id=' + id + '&promo_id=' + promo_id + '&location=' + location + '&cid=' + cid + '&element=' + element + '&cas_name=' + casino + '&position=' + position + '';

	if (link){
		e.preventDefault();
		window.open(link);
	} else{
		return false;
	}
}

/**
 * Google Rating
 */
function starsHover() {
	var ratingInner = $(".stars_rating.g_rating .g_rating_inner"),
		ratingInnerWidth = ratingInner.innerWidth(),
		ratingBlock = $(".stars_rating.g_rating"),
		id = $(".g_rating_block .count_number").attr('data-id');
	ratingCount = $(".g_rating_block .count_number span");
	ratingBlock.on("click", function () {
		if (!getCookie('added_rating' + id) && !$(this).data("clicked")) {
			ratingCount.text(parseInt(ratingCount.text()) + 1);
			console.log(ratingCount.text());
			var data = {
				'action': 'add_rating_count',
				'count': ratingCount.html(),
				'id': id,
				'nonce': rating_ajax.nonce
			};
			$.ajax({
				url: '/wp-admin/admin-ajax.php',
				data: data,
				type: 'POST',
				success: function (data) {
					if (data) {
						ratingCount.text(data);
					}
				}
			});
		}
		setCookie('added_rating' + id, 'true', 10);
		$(this).data("clicked", !0)
	});
	if (!getCookie('added_rating' + id) && !$(this).data("clicked")) {
		ratingBlock.on("mousemove", function (e) {
			if (!$(this).data("clicked")) {
				var ratingInnerWidth = e.clientX - $(this).offset().left;
				ratingInner.css("width", ratingInnerWidth)
			}
		});
		ratingBlock.on("mouseleave", function (e) {
			ratingInner.css("width", ratingInnerWidth)
		})
	}
}

/*Scroll top*/
function showScrollTop() {
	if ($(this).scrollTop() > 100) {
		$("#to_top").addClass('active');
	} else {
		$("#to_top").removeClass('active');
	}
}

$(document).ready(function () {
	"use strict";
	var siteBody = $("body");
	/*Init*/
	siteBody.addClass(currentBrowser()).addClass(detectmob());
	showScrollTop();
	toReferral();
	tableExpand();
	tableResExpand();
	if (siteBody.hasClass('single-casinos') && $(window).width() > 1024) {
		highlightTocSidebar();
	}
	$(window).scroll(function () {
		showScrollTop();
		if (siteBody.hasClass('single-casinos') && $(window).width() > 1024) {
			highlightTocSidebar();
		}
	});
	if ($(".g_rating")[0]) {
		starsHover();
	}

	$('.sidebar_table_of_content a').on("click", function (event) {
		event.preventDefault();
		var id = $(this).attr("href");
		var top = $(id).offset().top - 20;
		$('html, body').animate({scrollTop: top}, 1000);
	});
	//iframe
	$('.iframe_play').on('click', function () {
		$(this).addClass('hidden');
		$('.iframe').addClass('hidden_shadow');
		var iframe = $('.iframe iframe');
		var iframe_link = iframe.attr('data-iframe-link');
		iframe.attr('src', iframe_link);
	});

	/*Scroll top Click*/
	$("#to_top").on("click", "a", function (event) {
		event.preventDefault();
		var id = $(this).attr('href'),
			top = $(id).offset().top;
		$('html, body').animate({scrollTop: top}, 1000);
	});
	/*Menu*/
	$("#menuOpen button.hamburger").on('click', function (e) {
		$('#mainMenu').toggleClass("opened");
		$(this).parent().toggleClass("opened");
		$(this).toggleClass('is-active');
		siteBody.toggleClass('shadow');
	});
	$('#mainMenu li.menu-item-has-children').append('<span class="subMenuButton i-angle-down"></span>');
	$('.subMenuButton').on('click', function () {
		var t = $(this);
		if (t.hasClass('open')) {
			t.removeClass('open').prev().slideUp(300);
		} else {
			t.removeClass('open').prev().slideDown(300);
			t.addClass('open');
		}
	});
	//Menu Close
	$(document).mouseup(function (e) {
		var div = $("#mainMenu,#menuOpen");
		if (!div.is(e.target)
			&& div.has(e.target).length === 0) {
			div.removeClass('opened');
			$('.hamburger').removeClass('is-active');
			siteBody.removeClass('shadow');
		}
	});
	//WPCF7
	$(this).on('click', '.wpcf7-not-valid-tip', function () {
		$(this).prev().trigger('focus');
		$(this).fadeOut(500, function () {
			$(this).remove();
		});
	});
	//Privacy
	if (!getCookie('privacy')) {
		var privacy = $('#privacy');
		privacy.show();
	}
	$('#privacy .privacy_close').on('click', function () {
		$('#privacy').addClass('hide');
		setCookie('privacy', 'true', 1);
	});

	/**
	 * Writers AJAX
	 */
	$('.slots_loadmore').on('click', function () {
		var button = $(this).parent();
		$(this).text('Loading...');
		var data = {
			'action': 'slots_load',
			'page': slotsObj.current_page,
			'relation_slots': $(this).attr('data-related-slots'),
			'max_pages': slotsObj.max_pages,
		};
		$.ajax({
			url: slotsObj.ajaxurl,
			data: data,
			type: 'POST',
			success: function (data) {
				if (data) {
					button.children().text('Load More').parent().before(data);
					slotsObj.current_page++;
					if (slotsObj.current_page == slotsObj.max_pages) button.remove();
				} else {
					button.remove();
				}
			}
		});
	});
});

$(document).ready(function () {
	var modal = $('.js-modal');

	setTimeout(function () {
		modal.addClass('visible');
	}, 400);

	$('.js-modal-close').on('click', function () {
		modal.css('display', 'none');
	});

	var faq_row = $('.faq-row');
	faq_row.click(function(){
		$(this).next().slideToggle(100)
		$(this).find('.faq-toggle').toggleClass('faq-toggle-active')
	})

	if (faq_row[0]) {
		faq_row[0].click()
	}

	var lang = $('.current-lang');
	var lang_switch = $('.language-switcher');
	lang.click(function (e) {
		lang.toggleClass('open');
		lang_switch.toggle();
		e.stopPropagation();
	})
	$(document).click(function(e) {
		lang_switch.hide();
		lang.removeClass('open');
	});

});

/**
 * Responsive tables
 */
$(document).ready(function () {
	const mq = window.matchMedia( "(max-width: 1025px)" );

	if ( mq.matches ) {
		$('.content_box table').each(function () {
			$(this).wrap('<div class="responsive-table"></div>');
		});
	}
});

/**
 * Spoiler
 */
$(document).ready(function () {
	let mq = window.matchMedia( "(min-width: 1025px)" );

	$('.spoiler').each(function () {
		const spoiler = $(this);

		if ( mq.matches && spoiler.hasClass('casino-overall-rating__data-summary') ) {
			$('.spoiler-heading', spoiler).unbind('click');
		} else {
			$('.spoiler-heading', spoiler).click(function () {
				spoiler.toggleClass('open');
				$('.spoiler-content', spoiler).slideToggle(200);
			});
		}
	});
});
