/**
 * Track visibility of conversion elements
 */
class trackVisibilityClass {
    constructor(callback, options = {}) {
        this.callback = callback;
        this.options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
            ...options
        };

        this.observer = new IntersectionObserver(this._handleIntersect.bind(this), this.options);
    }

    _handleIntersect(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.callback(entry.target);
                this.unobserve(entry.target); // Run only once, then stop observing
            }
        });
    }

    observe(element) {
        this.observer.observe(element);
    }

    unobserve(element) {
        this.observer.unobserve(element);
    }

    disconnect() {
        this.observer.disconnect();
    }

	sendEvent(element) {
        let eventLabel = element.getAttribute('data-casino-name') || element.getAttribute('data-betting') || element.getAttribute('data-casino'),
            position = element.getAttribute('data-position'),
            casinoID = element.getAttribute('data-ref') || element.getAttribute('data-casino-target') || element.getAttribute('data-refer'),
            eventAction = element.getAttribute('data-ga-context-element'),
            bonusLabel = element.getAttribute('data-bonus-label'),
            bonusValue = element.getAttribute('data-bonus-value'),
            bonusID = element.getAttribute('data-bonus-id');

		let data = {
            'event': 'GAevent',
            'eventCategory': 'elementView',
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        }

        if(bonusLabel){
            data.eventBonusName = bonusLabel;
        }

        if(bonusValue){
            data.eventBonusConditions = bonusValue;
        }

        if(casinoID){
            data.eventLabelID = casinoID * 1;
        }

        if(bonusID){
            data.eventBonusID = bonusID * 1;
        }

        if(position){
            data.eventPosition = position * 1;
        }

        dataLayer.push(data);
	}

    isAncestorVisible(element) {
        if (!element) {
            return false;
        }

        var parentNode = element.parentNode;

        while (parentNode && parentNode.nodeType === 1) {
            var computedStyle = window.getComputedStyle(parentNode);

            // Check for opacity, display, or visibility
            var opacity = parseFloat(computedStyle.getPropertyValue('opacity'));
            var display = computedStyle.getPropertyValue('display');
            var visibility = computedStyle.getPropertyValue('visibility');

            if (opacity === 0 || display === 'none' || visibility === 'hidden') {
                return false; // Ancestor is visible
            }

            parentNode = parentNode.parentNode;
        }

        return true; // No hidden ancestor found
    }
}

function trackVisibility(elements) {

	if ( global.gtm_track_view ) {
        return false;
    }

    const observer = new trackVisibilityClass(element => {
        observer.sendEvent(element);
    });

    elements.forEach(element => observer.observe(element));

}

/**
 * Track visibility on whole page except casino constructor blocks
 */
function trackVisibilityElements(selector = '[data-ga-context-element]') {
    const elements = document.querySelectorAll(selector);
    const filteredElements = Array.from(elements).filter(element => !element.closest('.swiper-slide-duplicate'));
	trackVisibility(filteredElements);
}

jQuery(document).ready(function(){
	trackVisibilityElements();
});
