(function() {
	document.addEventListener('DOMContentLoaded', function() {
		const comparisonTables = document.querySelectorAll('.casino-comparison__wrapper');

		comparisonTables.forEach(table => {
			const target = table.querySelector('.casino-comparison__row--header .casino-comparison__label');
			const rows = table.querySelectorAll('.casino-comparison__row');
			const labels = table.querySelectorAll('.casino-comparison__row:nth-child(n+2) .casino-comparison__label');

			rows.forEach(row => {
				let totalWidth = 0;

				for (let cell of row.children) {
					totalWidth += cell.offsetWidth;
				}

				row.style.width = totalWidth + 'px';
			});

			if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
				labels.forEach(label => {
					label.classList.add('sticky');
				});
			} else {
				const observer = new IntersectionObserver((entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							labels.forEach(label => {
								label.classList.remove('sticky');
							});
						} else {
							labels.forEach(label => {
								label.classList.add('sticky');
							});
						}
					});
				}, {
					root: table,
					rootMargin: '0px',
					threshold: 0.33
				});

				observer.observe(target);
			}
		});
	});
})();
