(function () {
    // IE polyfill
    var ElementPrototype = window.Element.prototype;

    if (typeof ElementPrototype.matches !== 'function') {
        ElementPrototype.matches = ElementPrototype.msMatchesSelector || ElementPrototype.mozMatchesSelector || ElementPrototype.webkitMatchesSelector || function matches(selector) {
            var element = this;
            var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
            var index = 0;

            while (elements[index] && elements[index] !== element) {
                ++index;
            }

            return Boolean(elements[index]);
        };
    }

    if (typeof ElementPrototype.closest !== 'function') {
        ElementPrototype.closest = function closest(selector) {
            var element = this;

            while (element && element.nodeType === 1) {
                if (element.matches(selector)) {
                    return element;
                }

                element = element.parentNode;
            }

            return null;
        };
    }
})();

document.addEventListener('click', function (event) {
	const element = event.target.closest('[data-ga-convert-element]');

    if (!element) {
        return;
    }

    const eventAction = element.dataset.gaConvertElement;
    const eventLabel = element.dataset.betting || element.dataset.casino || element.dataset.casinoName;

	const id = element.dataset.casinoTarget;
	const position = element.dataset.position;

	const bonusID = element.dataset.bonusId;
	const bonusLabel = element.dataset.bonusLabel;
	const bonusValue = element.dataset.bonusValue;

    var data = {
        'event': 'GAevent',
        'eventCategory': 'toCasino',
        'eventAction': eventAction,
        'eventLabel': eventLabel
    };

    if (eventLabel) {
        data.eventLabel = eventLabel;
    }
	if(id) {
		data.eventLabelID = id * 1;
	}
	if(position) {
		data.eventPosition = position * 1;
	}
	if(bonusID){
		data.eventBonusID = bonusID * 1;
	}
	if(bonusLabel){
		data.eventBonusName = bonusLabel;
	}
	if(bonusValue){
		data.eventBonusConditions = bonusValue;
	}

	//console.log('data', data);
    dataLayer.push(data);
}, false);

$ = jQuery;
//SVG
$ = jQuery;
$('img.img_svg').each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    $.get(imgURL, function (data) {
        var $svg = jQuery(data).find('svg');
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID)
        }
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg')
        }
        $svg = $svg.removeAttr('xmlns:a');
        if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }
        $img.replaceWith($svg)
    }, 'xml')
});

// browsers
function currentBrowser() {
    var o = !!window.opr && !!opr.addons || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0,
        e = "undefined" != typeof InstallTrigger,
        i = /constructor/i.test(window.HTMLElement) || "[object SafariRemoteNotification]" === (!window.safari || "undefined" != typeof safari && safari.pushNotification).toString(),
        n = !!document.documentMode, r = !n && !!window.StyleMedia, t = !!window.chrome && !!window.chrome.webstore,
        d = (t || o) && !!window.CSS;
    return o ? "opera" : e ? "firefox" : i ? "safari" : n ? "ie" : r ? "edge" : t ? "chrome" : d ? "blink" : void 0
}

//Mob
function detectmob() {
    return navigator.userAgent.match(/Android/i) ? "android" : navigator.userAgent.match(/webOS/i) ? "webOS" : navigator.userAgent.match(/iPhone/i) ? "iPhone" : navigator.userAgent.match(/iPad/i) ? "iPad" : navigator.userAgent.match(/iPod/i) ? "iPod" : navigator.userAgent.match(/BlackBerry/i) ? "blackBerry" : !!navigator.userAgent.match(/BlackBerry/i) && "windowsPhone"
}

/*Select*/
$('select').each(function () {
    var $this = $(this), numberOfOptions = $(this).children('option').length;

    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());

    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    var $listItems = $list.children('li');

    $styledSelect.click(function (e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function () {
            $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
    });

    $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel')).attr('data-select-id', $(this).attr('rel'));
        $list.hide();
    });

    $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
    });
});

/*COOKIES*/
function setCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else var expires = '';
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return false;
}

function deleteCookie(name) {
    setCookie(name, "", -1);
}

(function () {
	const toggleButton = document.querySelectorAll('.toggler');

	toggleButton.forEach(button => {
		button.addEventListener('click', event => {
			const element = event.currentTarget;

			let toggleLabel = element.dataset.active;

			element.classList.toggle('active');
			element.innerText = toggleLabel;

			const parent = element.closest('.casino-overall-rating__description');

			parent.classList.toggle('is-closed');

			const isOpened = parent.classList.contains('is-closed');

			if (isOpened) {
				toggleLabel = element.dataset.default;

				element.innerText = toggleLabel;

				parent.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				});
			}
		});
	});
})();

// Custom scrollbar
(function($){
	$(window).on('load',function(){
		$('.casino-comparison__wrapper').mCustomScrollbar({
			axis: 'x',
			scrollbarPosition: 'outside',
			mouseWheel: {
				enable: false,
			}
		});
	});
})(jQuery);
