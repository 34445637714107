var tournament = {
	upperToggler: $('.js-tournament-info-toggler'),
	bottomToggler: $('.js-tournament-toggler'),
	contentToggler: $('.js-content-toggler'),

	getTopParent: function (object) {
		return object.closest('.js-tournament');
	},

	toggleClass: function (object) {
		object.toggleClass('is-opened');
	},

	setLabels: function (object) {
		object.text(object.text() === object.data('default') ? object.data('active') : object.data('default'));
	},

	toggleBody: function (object) {
		object.toggleClass('active');
		object.find('.js-tournament-body').slideToggle();
	},

	runTimers: function () {
		$('.js-tournament').each(function () {
			var $timerRow = $(this).find('.js-tournament-timer-row');
			var $timerLeft = $(this).find('.js-tournament-timer').data('time-left');

			// Local timezone
			var deadline = new Date($timerLeft);
			var current = new Date();

			// UTC timezone
			var deadlineUtc = new Date(deadline.getTime() + deadline.getTimezoneOffset() * 60000);
			var currentUtc = new Date(current.getTime() + current.getTimezoneOffset() * 60000);

			// Compare deadline with current date
			if (deadlineUtc > current) {
				$timerRow.removeClass('is-hidden');

				$(this).find('.js-countdown').timeTo({
					timeTo: deadlineUtc,
					theme: 'black',
					displayDays: 2,
					callback: function () {
						$timerRow.addClass('is-hidden');
					}
				});
			}
		});
	},

	insertLetters: function () {
		$('.js-tournament').each(function () {
			var $timer = $(this).find('.js-countdown');
			var id = 1;

			$($timer.find('.first').get().reverse()).each(function () {
				$(this).addClass('countdown-' + id);

				++id;
			});

			$timer.find('.countdown-4 ul li:first-child, .countdown-3 ul li:first-child').each(function () {
				var $symbols = $(this).text();

				if (0 === parseInt($symbols)) {
					$(this).addClass('is-hidden');
				}
			});
		});
	}
}

tournament.upperToggler.on('click', function () {
	tournament.toggleClass($(this));
	tournament.getTopParent($(this)).find('.js-tournament-toggler').toggleClass('is-visible');
	tournament.setLabels($(this));
	tournament.toggleBody(tournament.getTopParent($(this)));
});


tournament.bottomToggler.on('click', function () {
	$(this).toggleClass('is-visible');

	var $tournament = tournament.getTopParent($(this));
	var $upperToggler = $tournament.find('.js-tournament-info-toggler');

	tournament.toggleClass($upperToggler);
	tournament.setLabels($upperToggler);
	tournament.toggleBody($tournament);
});


tournament.contentToggler.on('click', function () {
	$(this).closest('.js-terms').find('.js-terms-content').slideToggle();

	tournament.setLabels($(this));
});


$(document).ready(function () {
	var $tournaments = $(".js-tournaments");
	var perPage = parseInt($tournaments.data('pagify')) || 0;

	if (perPage > 0) {
		$tournaments.pagify(perPage, ".js-tournament");
	}

	tournament.runTimers();
	tournament.insertLetters();
});
